/* open-sans-300 - greek-ext_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../../../../public/fonts/open-sans-v36-greek-ext_latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-regular - greek-ext_latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../../../public/fonts/open-sans-v36-greek-ext_latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-600 - greek-ext_latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../../../../public/fonts/open-sans-v36-greek-ext_latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-700 - greek-ext_latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../../../../public/fonts/open-sans-v36-greek-ext_latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-800 - greek-ext_latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../../../../public/fonts/open-sans-v36-greek-ext_latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
.page {
  width: 1170px;
  height: 670px;
  margin: auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  page-break-after: always;
  margin-bottom: 0px;
  background-color: red;
  margin-bottom: 20px;
  background: url("../../../../public/images/weekly_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Open Sans";
  overflow: hidden;
  border: 1px solid #ddd;
}
.sentiment-algo-logo{
  position: absolute;
    right: 0;
    top: 5px;
    width: 121px;
}
.continue-order-select{
  height: 29px;
  margin-top: 84px;
  padding: 5px;
}
.page-container {
    width: 1170px;
    height: 670px;
    margin: auto;
    position: relative;
    z-index:999
    
  }
  .react-date-picker__calendar{
    z-index:9999 !important
  }
.weekly-positive-sector .weekly-sector-perf{
    color:rgb(86,188,108)
}
.weekly-negative-sector .weekly-sector-perf{
    color:red
}
.weekly-blue-page {
  background: url("../../../../public/images/blue_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.weekly-cropped-page{
    background: url("../../../../public/images/weekly_cropped_background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.weekly-chart-order {
  position: absolute;
  left: 16px;
  top: 5px;
  /* padding: 10px; */
  font-size: 12px;
  /* font-weight: bold; */
  color: #fff;
  width: 20px;
  z-index: 999;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekly-perf-table {
  background-color: rgb(35, 43, 62);
  color: #fff;
  width: 300px;
}
.weekly-perf-table > tr > td:nth-child(3) {
  text-align: right;
}
.weekly-perf-table > tr > td:nth-child(4) {
  text-align: right;
}
.weekly-perf-table td {
  padding: 4px 8px;
}
.weekly-chart-continue-part{
  width: calc(50% - 80px);
  overflow: hidden;
  border-radius: 5px;
}
.weekly-chart-title{
    background: linear-gradient(90deg, rgba(48, 119, 189, 1) 0%, rgb(7 78 220) 0%, rgba(0, 188, 212, 1) 100%);
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    padding: 1px 12px;
    font-size: 12px;
    position: absolute;
    right: 25px;
    top: 23px;
}

.perf-table-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
  width: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 2px;
}

.perf-table-blue {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
  width: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 2px;
}
.weekly-page-index{
    position: fixed;
    left:0px;
    top:50px;
    z-index:999;
    max-width: 250px;
    overflow:hidden;
    bottom:0;
}
.weekly-page-index > div>div{
    padding: 2px 10px;
    cursor:pointer;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    padding-left: 4px;
    overflow: hidden;
}
.weekly-image-modal-portal{
    z-index: 999999;
}
.weekly-image-modal-open{
    position: absolute;
    right: 41px;
    top: 10px;
}

.weekly-page-index > div>div:hover{
   background-color: rgb(59, 59, 228);
   padding-left: 4px;
   color:#fff
}

.perf-table-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
  width: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 2px;
}
.weekly-order-green {
  background-color: rgb(0, 193, 0);
}
.print-only-block {
  display: none;
}

.print-only-flex {
  display: none;
}
.print-only-inline{
  display: none;
}
.print-only-inline-block{
    display: none;
}
.weekly-comment-table {
  border-collapse: collapse;
  width: 100%;
}
.weekly-comment-table td {
  padding: 6px;
}
.weekly-comment-table > .upper-border > td {
  border-top: 2px solid rgb(12, 160, 189);
  font-weight: bold;
  padding: 6px;
}
.weekly-bist-table {
  border-collapse: collapse;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.weekly-order-black{
    background-color: #333;
}
.weekly-bist-table td {
  padding: 6px;
  font-size: 16px;
}
.weekly-bist-table td:nth-child(2) {
  text-align: right;
}
.weekly-page-settings{
    position: fixed;
    right: 0;
    top: 57px;
    padding: 10px;
}
.weekly-bist-table > .upper-border > td {
  border-top: 2px solid rgb(12, 160, 189);
  padding: 6px;
}
.weekly-bist-table > .lower-border > td {
  border-bottom: 2px solid rgb(12, 160, 189);
  padding: 6px;
}
.weekly-comment-table > .lower-border > td {
  border-bottom: 2px solid rgb(12, 160, 189);
}
.weekly-comment-table tr:nth-child(even) {
  background-color: rgb(209, 224, 240);
  padding: 6px;
}

.weekly-bist-table tr:nth-child(odd) {
  background-color: rgb(209, 224, 240);
  padding: 6px;
}
.comment-support-table {
  width: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.comment-support-table td {
  text-align: center;
}
.comment-support-table tr:nth-child(even) {
  background-color: rgb(209, 224, 240);
  padding: 6px;
}
.comment-support-table > .lower-border > td {
  border-bottom: 2px solid rgb(12, 160, 189);
}
.comment-support-table > .upper-border > td {
  border-top: 2px solid rgb(12, 160, 189);
}
.hide-page {
    position: absolute;
    right: 9px;
    top: 9px;
    cursor: pointer;
    z-index: 99999999;
}
.weekly-badge {
  background-color: #237cd3;
  width: 460px;
  padding: 20px 40px;
  color: #fff;
  border-radius: 2005px;
  font-size: 24px;
  position: absolute;
  bottom: 49px;
  right: 152px;
  text-align: center;
  font-weight: bold;
}
.page-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.weekly-chip {
  display: inline-block;
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 14px;
}
.weekly-chip-container {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.weekly-chip-group {
  background-color: rgb(26, 31, 46);
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
  display: flex;
  gap: 5px;
}
.weekly-preview .no-print{
    display: none !important;
}
.weekly-preview .print-only-block {
    display: block !important;
}

.weekly-preview .print-only-flex {
    display: flex !important;
}
.weekly-preview .print-only-inline {
  display: inline !important;
}
.weekly-preview .print-only-inline-block {
    display: inline-block !important;
}
.weekly-preview .print-only-list-item {
    display: list-item !important;
}
.weekly-preview .weekly-page-settings {
    display: block !important
}


.weekly-preview .moveable-control-box{
    display: none !important;
}
.weekly-preview .moveable-control{
    display: none !important;
}


body,
#root {
  background-color: #fff;
  margin: 0;
}
.weekly-image-selection{
    display: flex;
    gap:10px;
    flex-wrap: wrap;
}
.moveable-control{
    width: 10px !important;
    height: 10px !important;
    margin-top: -5px!important;
    margin-left: -5px!important;
}
.moveable-origin{
    display: none!important;
}
.weekly-image-selection div{
    background-color: #d3d3d3;
    padding:10px;
    cursor: pointer;;
    border:1px solid #ccc;
    justify-content: center;
    align-items: center;
    display:flex;
}
.weekly-sentiment-resistant{
    display: flex;
    gap:10px;
    justify-content: space-between;
    width: 200px;
    margin-bottom: 10px;;
}
.weekly-sentiment-resistant >input{
    width: 91px;
}
.weekly-image-selection div:hover{
    background-color: #ddd;
    border: 1px solid rgb(142, 142, 231);
}
.weekly-image-selection img{
    width: 40px;
}
.print-only-list-item{
    display:none;
  }
  
@media print {
    .moveable-control{
        display: none!important;
    }
    .moveable-control-box{
        display: none!important;
    }
  .no-print,
  .no-print * {
    display: none !important;
  }
  .weekly-preview .weekly-page-settings {
    display: none !important
}
  .bp4-navbar {
    display: none !important;
  }
  .print-only-block {
    display: block;
  }
  .print-only-flex {
    display: flex;
  }
  .print-only-inline-block {
    display: inline-block;
  }
  .print-only-list-item{
    display: list-item;
  }
  
  body,
  #root {
    background-color: #fff;
    margin: 0;
  }
  @page {
    size: 11.7in 6.7in;
    margin: 0;
  }
  .page {
    page-break-after: always;
    margin-bottom: 0px;
    border:none;
  }
  .weekly-chart-continue-part{
    width: 50%;
    overflow: hidden;;
    border-radius: 5px;
  }
  
}

@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

.App {
  text-align: center;
}
#root {
  background-color: #f5f5f5;
  min-height: 100vh;
}
.data-grid th {
  padding: 5px 10px;
}
.cell {
  padding: 5px !important;
  height: 29px !important;
}

.cell.read-only {
  color: #000 !important;
}
.container {
  width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.full-data-grid {
  width: 100%;
}
.data-grid-container .data-grid .cell > input {
  height: 28px !important;
  width: 100% !important;
}
.cell.selected.editing {
  padding: 0px !important;
}
.bulletin-list {
  width: 100%;
  border-collapse: collapse;
}
.bulletin-list td {
  border: 1px solid #ddd;
  padding: 5px;
}
.bulletin-list th {
  border: 1px solid #ddd;
  padding: 5px;
  font-weight: bold;
  text-align: left;
}
.bulletin-weekly-list {
  width: 100%;
  border-collapse: collapse;
}
.bulletin-weekly-list tr:hover>td
{
  background-color: #f6f6f6;
}
.colorizer {
  height: 32px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  
}
.colorizer > div {
  border-radius: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid #bbb;
}
.colorizer .red-color {
  background-color: #fb4040;
}
.colorizer .red-color:hover {
  background-color: #fc5a5a;
}

.colorizer .green-color {
  background-color: #78df78;
}
.colorizer .green-color:hover {
  background-color: #8be48b;
}
.page-tab{
width:100%;
}
.bp4-tab{
outline:none
}
.red-cell{
  background-color:#ffbebe !important;
}
.green-cell{
  background-color:#bcffd8 !important;
}
.api-date-input input{
  max-width: 100px;
}

.bordered-table tr,td,th{
border: 1px solid #ddd;
padding: 6px;
}
.bordered-table{
  border-collapse: collapse;
}
.hover-table tr:hover >td{
background-color: #eee;
}